import React from 'react';
import PropTypes from 'prop-types';
import { FORMAT, dayjs } from 'utils/date';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';

import { formatAsPoundsOrPence } from 'utils/currency';

import AnchorLink from 'components/AnchorLink';
import Card from '@johnlewispartnership/wtr-ingredients/ingredients/Card';
import ChevronRight from '@johnlewispartnership/wtr-ingredients/dist/Icon/ChevronRight';
import SuccessIcon from '@johnlewispartnership/wtr-ingredients/dist/Icon/Success';
import RemoveOutline from '@johnlewispartnership/wtr-ingredients/dist/Icon/RemoveOutline';
import { shopFromPreviousURL, viewOrderURL } from 'constants/urls';

import styles from 'components/PreviousOrderSummary/PreviousOrderSummary.scss';

const PreviousOrderSummary = ({
  date,
  itemCount,
  numberOfItems,
  orderId,
  showNumberOfItems,
  status,
  total,
}) => {
  const isCancelled = status === 'CANCELLED';
  const hasItems = itemCount > 0;
  const willShowNumberOfItems = showNumberOfItems && hasItems;
  const shopFromUrl = `${shopFromPreviousURL(orderId)}?wrint=1-YLO-shop`;
  const viewUrl = `${viewOrderURL(orderId)}?wrint=1-YLO-view`;

  return (
    <Card>
      <div className={styles.previousOrderCard}>
        <h3 className="sr-only">Previous order summary</h3>
        <div aria-hidden className={styles.statusIcon}>
          {isCancelled ? (
            <RemoveOutline className={styles.cancelledIcon} />
          ) : (
            <SuccessIcon className={styles.successIcon} />
          )}
        </div>

        {hasItems && (
          <AnchorLink
            className={styles.mobileViewOrderLink}
            data-testid={`view order ${orderId}`}
            to={viewUrl}
          >
            View order
          </AnchorLink>
        )}
        <dl
          className={styles.previousOrderSummary}
          data-testid="previous-order-summary"
          data-order-id={orderId}
        >
          <dt className={classNames('sr-only', styles.col, styles.status)}>Order status</dt>
          <dd className={classNames(styles.col, styles.status)}>
            {capitalize(status).replace('_', ' ')}
          </dd>
          <dt className={classNames('sr-only', styles.col, styles.date)}>Date of order</dt>
          <dd className={classNames(styles.col, styles.date)}>
            {dayjs(date).tz().format(FORMAT.LONG_DAY_MONTH)}
          </dd>
          <dt
            className={classNames('sr-only', styles.col, styles.numberOfItems)}
            aria-hidden={!willShowNumberOfItems}
          >
            Number of items in order
          </dt>
          <dd
            className={classNames(styles.col, styles.numberOfItems)}
            aria-hidden={!willShowNumberOfItems}
          >
            {willShowNumberOfItems && `${numberOfItems} ${numberOfItems > 1 ? 'items' : 'item'}`}
          </dd>
          {hasItems && (
            <>
              <dt className={classNames('sr-only', styles.col, styles.total)}>Total cost</dt>
              <dd className={classNames(styles.col, styles.total)}>
                {formatAsPoundsOrPence(total)}
              </dd>
            </>
          )}
          {hasItems && (
            <>
              <dt className={classNames('sr-only', styles.col, styles.shopFromThisOrder)}>
                Link to shop from the order of {dayjs(date).tz().format(FORMAT.LONG_DAY_MONTH)}
              </dt>
              <dd className={classNames(styles.col, styles.shopFromThisOrder)}>
                <AnchorLink data-testid="shop-from-order-link" to={shopFromUrl}>
                  Shop from this order
                </AnchorLink>
              </dd>
            </>
          )}
          {hasItems && (
            <>
              <dt className={classNames('sr-only', styles.col, styles.viewOrder)}>
                Link to view more details for the order of
                {dayjs(date).tz().format(FORMAT.LONG_DAY_MONTH)}
              </dt>
              <dd className={classNames(styles.col, styles.viewOrder)}>
                <AnchorLink data-testid={`view order ${orderId}`} to={viewUrl}>
                  View order
                </AnchorLink>
              </dd>
            </>
          )}
        </dl>
        <div aria-hidden="true" className={classNames(styles.col, styles.actionMobile)}>
          {hasItems && <ChevronRight />}
        </div>
      </div>
    </Card>
  );
};

PreviousOrderSummary.propTypes = {
  date: PropTypes.string.isRequired,
  itemCount: PropTypes.number.isRequired,
  numberOfItems: PropTypes.number.isRequired,
  orderId: PropTypes.string.isRequired,
  showNumberOfItems: PropTypes.bool,
  status: PropTypes.oneOf(['COMPLETED', 'CANCELLED', 'REFUND_PENDING']).isRequired,
  total: PropTypes.number,
};

PreviousOrderSummary.defaultProps = {
  showNumberOfItems: false,
  total: undefined,
};

export default PreviousOrderSummary;
