import { connect } from 'react-redux';

import PreviousOrderSummary from 'components/PreviousOrderSummary/PreviousOrderSummary';

import {
  getItemCount,
  getNumberOfItems,
  getOrderTotal,
  getSlotStartTime,
  getStatus,
} from 'redux/modules/orders/selectors/get-order';

export default connect((state, { orderId }) => ({
  date: getSlotStartTime(state, orderId),
  itemCount: getItemCount(state, orderId),
  numberOfItems: getNumberOfItems(state, orderId),
  status: getStatus(state, orderId),
  total: getOrderTotal(state, orderId),
}))(PreviousOrderSummary);
