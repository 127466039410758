import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import urls from 'constants/urls';

import AnchorLink from 'components/AnchorLink';
import PreviousOrderSummary from 'components/PreviousOrderSummary';
import { Typography } from '@johnlewispartnership/wtr-ingredients/foundations/typography';

import styles from 'components/ShopFromRecentOrder/ShopFromRecentOrder.scss';

const ShopFromRecentOrder = ({
  previousOrderId,
  listsHubPage,
  cqResponsiveClasses,
  analyticsTags,
}) => {
  if (!previousOrderId) return null;
  const ordersUrl = `${urls.myOrdersPage}?wrint=1-YLO-allprevious`;

  return (
    <article
      data-test="ShopFromRecentOrder"
      className={classNames(styles.recentOrder, cqResponsiveClasses, {
        [styles.responsive]: !listsHubPage,
      })}
      {...analyticsTags}
    >
      {/* The internal logic to this component about when the component should render or not needs reviewing. */}
      <Typography element="h2" styleAs="sectionHeadingLight">
        Your last order
      </Typography>
      <div className={styles.previousOrderSummary}>
        <PreviousOrderSummary orderId={previousOrderId} />
      </div>
      <AnchorLink className={styles.viewAll} to={ordersUrl}>
        View all previous orders
      </AnchorLink>
    </article>
  );
};

ShopFromRecentOrder.propTypes = {
  analyticsTags: PropTypes.shape({}),
  cqResponsiveClasses: PropTypes.arrayOf(PropTypes.string),
  previousOrderId: PropTypes.string,
  listsHubPage: PropTypes.bool,
};

ShopFromRecentOrder.defaultProps = {
  analyticsTags: undefined,
  cqResponsiveClasses: undefined,
  previousOrderId: null,
  listsHubPage: true,
};

ShopFromRecentOrder.displayName = 'ShopFromRecentOrder';

export default ShopFromRecentOrder;
